@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
header {
  height: 100vh;
  padding-top: 3rem;
  overflow: hidden;
}
/*Social Media css*/
.header__socials {
  background: rgba(13, 7, 41, 0.3);
  width: max-content;
  /*size of background*/
  padding: 0.0007rem 1.2rem;
  /*center the socials*/
  left: calc(50% - 1.5rem);
  transform: translateX(-30%);
  border-radius: 1rem;
  display: flex;
  gap: 2rem;
  position: relative;
  bottom: -2rem;
  align-items: center;
  font-size: 1.5rem;
}

.header__container {
  text-align: center;
  color: #fff;
  

  position: relative;
}


.header__container h1{
  font-size:50px;
  font-family: 'Black Han Sans', sans-serif;
  line-height:70px;
  margin-left:-10px;
  color: transparent;
  -webkit-text-stroke: 1px#fff;
  background: url(back.png);
  background-size: 800px;
  -webkit-background-clip:text;
  background-position: 0 0;
  animation: back 10s linear infinite;
}

@keyframes back{
  100%{
    background-position:800px 800px;
  }
}

.wrapper{
  height: 25px;
  display:flex;
  font-family: 'Black Han Sans', sans-serif;
  left: calc(50% - 11rem);
  margin-left:80px;
  position: relative;
}

span{
  display: block;
  height: 120%;
  padding-left:5px;
 color: whitesmoke;
 animation: spin_words 6s infinite;
}
@keyframes spin_words{
  10%{
    transform:translateY(-112%)
  }
  25%{
    transform:translateY(-100%)
  }
 
  50%{
    transform:translateY(-200%)
  }
 

}
.words{
  overflow: hidden;
}
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 2.5rem;
  justify-content: center;
}

.me {
  background: linear-gradient(var(--color-primary1), transparent);
  width: 22rem;
  height: 24rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 3rem;
  border-radius: 11rem 11rem 5rem 5rem;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.me:hover {
  background: linear-gradient(var(--color-primary), transparent);
  cursor:default;
}

@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
}
@media screen and (max-width: 900px) {
  header {
    height: 90vh;
  }
}
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
}

