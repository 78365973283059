.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 1rem 1rem;
  margin-bottom: 2rem;
}

.experience__container > div {
  background: var(--color-primary-variant);
  padding: 2.4rem 5rem;
  border-radius: 60rem;
  border: 1px solid transparent;
}
.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}
.experience__containers {
    display: grid;
    grid-template-columns:  1fr;
    gap:2rem;
    margin-top: 2rem;
}

.experience__containers > div {
  background: var(--color-primary-variant);
  padding: 2.4rem 5rem;
  border-radius: 60rem;
  border: 1px solid transparent;
}
.experience__containers > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__containers > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 5fr 1fr;
  row-gap: 2rem;
}
.experience__contentss {
  display: grid;
  grid-template-columns: 8fr 1fr;
  row-gap: 2rem;
}
.experience__details {
  display: flex;
  gap: 0.4rem;
  font-size: 0.9rem;

}
.experience__details-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;


}
.aboutMe{
    font-family: 'Black Han Sans', sans-serif;

}
/*.experience__details-icon{

}*/

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .experience__containers {
    grid-template-columns: 1fr;
    
  }
  .experience__containers > div {
    width: 80%;
    padding: 2rem;
    
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
  }
  .experience__contentss {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
    border-radius: 60rem;

  }
  .experience__containers {
    gap: 1rem;
    border-radius: 60rem;

  }
  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
  .experience__containers > div {
    width: 100%;
    padding: 2rem 1rem;
  }
  
}
